var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Игры"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"warning"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusCircleIcon"}}),_vm._v(" Импорт пакета ")],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.gamesList,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm },"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage:_vm.pageLength
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'dt')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.dt))+" ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{ name: 'game-edit', params: { id: props.row.id } }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}})],1),_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.total,"per-page":_vm.filters.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }