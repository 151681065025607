<template>
  <b-card title="Игры">

    <b-button variant="warning" class="mb-1">
      <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
      />
      Импорт пакета
    </b-button>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="gamesList"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
        >
          <b-avatar
              :src="props.row.avatar"
              class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'dt'">
          {{ formatDate(props.row.dt) }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-link
                :to="{ name: 'game-edit', params: { id: props.row.id } }"
                class="font-weight-bold"
            >
              <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
              />
            </b-link>

            <feather-icon
                icon="TrashIcon"
                class="mr-50"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <b-pagination
                :total-rows="total"
                v-model="filters.page"
                :per-page="filters.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BLink, BButton, BCard
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import store from '@/store';
import gameStoreModule from '@/views/game/gameStoreModule';
import {onUnmounted, ref, watch} from '@vue/composition-api';
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCardCode,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BLink,
    BButton
  },
  data () {
    return {
      pageLength: 20,
      columns: [
        {
          label: 'Игра',
          field: 'name',
        },
        {
          label: 'Дата',
          field: 'dt',
        },
        {
          label: 'Тема',
          field: 'type',
        },
        {
          label: 'Автор',
          field: 'author.name',
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  setup () {
    const GAMES_APP_STORE_MODULE_NAME = 'app-games'

    // Register module
    if (!store.hasModule(GAMES_APP_STORE_MODULE_NAME)) store.registerModule(GAMES_APP_STORE_MODULE_NAME, gameStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GAMES_APP_STORE_MODULE_NAME)) store.unregisterModule(GAMES_APP_STORE_MODULE_NAME)
    })

    const filters = ref({
      q: '',
      categories: [],
      ratings: null,
      page: 1,
      perPage: 15,
    })
    const sortBy = ref({ text: 'Rating', value: 'rating' })

    const gamesList = ref([])
    const total = ref(null)
    const fetchGames = (...args) => store.dispatch('app-games/fetchGamesList', ...args)

    const fetchGamesList = () => {
      fetchGames({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
          .then(response => {
            gamesList.value = response.data.data.map(function(item) {
              item.answer_visible = false
              return item
            })
            total.value = response.data.meta.total
          })
    }

    fetchGamesList()

    watch([filters, sortBy], () => {
      fetchGamesList()
    }, {
      deep: true,
    })

    return {
      gamesList,
      filters,
      sortBy,
      total,
      formatDate,
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
